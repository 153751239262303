@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: none;
  font-style: normal;
  text-align: left;
  zoom: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

table {
  border-collapse: collapse;
  font-family: inherit; }

h1, h2, h3, h4, h5 {
  font-size: 100%;
  font-weight: normal;
  line-height: 1; }

input, textarea, select {
  font-family: inherit;
  font-size: 16px; }

input[type="button"], input[type="text"], input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0; }

textarea {
  resize: none;
  -webkit-appearance: none;
  border-radius: 0; }

th, td {
  border-collapse: collapse; }

table th, table td {
  white-space: nowrap; }

ul, ol {
  list-style-type: none; }

img {
  vertical-align: text-bottom;
  vertical-align: -webkit-baseline-middle;
  max-width: 100%;
  height: auto;
  width: auto; }

a {
  color: #7fbe27;
  text-decoration: none; }

@media all and (min-width: 480px) {
  /* ボタンを右下に表示する */
  #pagetop {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: rgba(110, 110, 110, 0.5);
    border-radius: 50%;
    cursor: pointer; }
    #pagetop i {
      margin-top: 8px;
      color: white;
      font-size: 2em; }
  body {
    background: white; }
    body nav {
      width: 835px;
      text-align: center;
      margin: 0 auto; }
      body nav .nav_list {
        display: flex;
        justify-content: center;
        align-items: center; }
        body nav .nav_list li {
          width: 20%;
          margin: 0 15px; }
          body nav .nav_list li:last-child {
            margin-right: 0; }
    body iframe {
      width: 835px;
      height: 430px; }
    body .culeaf_wrapper {
      color: #444;
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      height: auto;
      line-height: 1.4;
      margin: 0px 0px 100px 0px;
      text-align: center;
      width: auto;
      max-width: 100%;
      justify-content: center; }
      body .culeaf_wrapper .contact_tell {
        text-align: center;
        margin-top: 20px; }
        body .culeaf_wrapper .contact_tell a {
          font-size: 60px;
          font-family: Oswald;
          font-weight: 800; }
          body .culeaf_wrapper .contact_tell a small {
            vertical-align: middle;
            font-size: 30px;
            margin-right: 5px; }
      body .culeaf_wrapper .culeaf_entry_btn {
        text-align: center;
        margin: 50px auto 10px; }
        body .culeaf_wrapper .culeaf_entry_btn a {
          display: inline-block;
          color: #fef23e;
          background-color: #7fbe27;
          border-radius: 20px;
          font-size: 40px;
          vertical-align: middle;
          padding: 15px 45px; }
      body .culeaf_wrapper .high_culeaf_entry_btn {
        text-align: center;
        margin: 50px auto 10px; }
        body .culeaf_wrapper .high_culeaf_entry_btn a {
          display: inline-block;
          color: #fef23e;
          background-color: #000000;
          border-radius: 20px;
          font-size: 30px;
          vertical-align: middle;
          padding: 15px 45px; }
      body .culeaf_wrapper .voice_entry_btn {
        text-align: center;
        margin: 50px auto 10px; }
        body .culeaf_wrapper .voice_entry_btn a {
          display: inline-block;
          color: #fef23e;
          background-color: #7fbe27;
          border-radius: 20px;
          font-size: 30px;
          vertical-align: middle;
          padding: 15px 45px; }
      body .culeaf_wrapper .sub_title {
        font-family: Oswald;
        text-align: center;
        font-size: 40px;
        font-weight: 900;
        margin: 20px 0 60px; }
      body .culeaf_wrapper .culeaf_instagram {
        text-align: center;
        margin: 10px 0 0;
        font-size: 13px; }
      body .culeaf_wrapper .culeaf_fa_icon {
        color: #444;
        font-size: 48px; }
      body .culeaf_wrapper .culeaf_stripe {
        min-width: 835px;
        height: 3px;
        margin: 70px 0;
        background: linear-gradient(-8deg, #7fbe27 50%, #fef23e 50%, #fef23e 100%, #7fbe27 100%, #7fbe27); }
      body .culeaf_wrapper .top_box .top_text {
        text-align: center;
        width: 835px;
        background-color: #7fbe27;
        color: #fef23e;
        font-family: 'Noto Sans JP';
        font-size: 50px;
        font-weight: 700;
        margin: 0 auto;
        padding: 20px 0;
        line-height: 1; }
        body .culeaf_wrapper .top_box .top_text i {
          font-size: 30px;
          align-items: center; }
        body .culeaf_wrapper .top_box .top_text small {
          color: #fff;
          font-size: 32px; }
      body .culeaf_wrapper .top_img_box {
        text-align: center; }
        body .culeaf_wrapper .top_img_box .main_text {
          width: 417.5px; }
        body .culeaf_wrapper .top_img_box .main_img {
          width: 417.5px; }
      body .culeaf_wrapper .info_area {
        margin: 20px 0; }
        body .culeaf_wrapper .info_area .info_text {
          text-align: center;
          font-size: 13px;
          margin-bottom: 30px;
          padding: 0 10px; }
        body .culeaf_wrapper .info_area .info_view_box {
          width: 835px;
          margin: 50px auto 0;
          padding: 30px 0;
          background-color: #fef23e; }
          body .culeaf_wrapper .info_area .info_view_box .info_view_text_img {
            text-align: center; }
            body .culeaf_wrapper .info_area .info_view_box .info_view_text_img .info_view_img {
              width: 556.66667px; }
          body .culeaf_wrapper .info_area .info_view_box .info_view_text {
            text-align: center;
            margin-top: 30px;
            line-height: 1.7; }
      body .culeaf_wrapper .pv_area {
        margin-top: 100px;
        text-align: center; }
        body .culeaf_wrapper .pv_area .pv_text {
          text-align: center;
          margin-top: 20px; }
      body .culeaf_wrapper .news_area {
        text-align: center;
        margin-top: 100px; }
        body .culeaf_wrapper .news_area p {
          margin-top: 20px; }
        body .culeaf_wrapper .news_area article {
          margin: 30px 0; }
          body .culeaf_wrapper .news_area article .post-content {
            width: 835px;
            color: #444;
            margin: 0 auto; }
            body .culeaf_wrapper .news_area article .post-content a {
              color: #444; }
            body .culeaf_wrapper .news_area article .post-content ul li {
              padding: 0 10px 20px; }
              body .culeaf_wrapper .news_area article .post-content ul li .info {
                margin-top: 20px; }
            body .culeaf_wrapper .news_area article .post-content .image {
              display: block;
              text-align: center; }
            body .culeaf_wrapper .news_area article .post-content .pagerbox .tablenav {
              text-align: center; }
              body .culeaf_wrapper .news_area article .post-content .pagerbox .tablenav .page-numbers {
                margin-right: 15px; }
      body .culeaf_wrapper .class_area {
        text-align: center;
        width: 835px;
        margin: 100px auto 0; }
        body .culeaf_wrapper .class_area .class_type_img {
          width: 835px;
          margin-top: 30px; }
        body .culeaf_wrapper .class_area .class_item {
          margin: 100px 0 0; }
          body .culeaf_wrapper .class_area .class_item h3 {
            text-align: center; }
            body .culeaf_wrapper .class_area .class_item h3 .class_title_img {
              width: 300px; }
          body .culeaf_wrapper .class_area .class_item .class_price {
            text-align: center;
            margin: 30px 0;
            line-height: 1.8; }
          body .culeaf_wrapper .class_area .class_item .class_high_list {
            font-size: 18px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 110px;
            height: 110px;
            margin: 0 auto;
            border-radius: 50%;
            border: solid 1px #7dccf4; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title .class_type_logo {
              text-align: center;
              font-family: 'Noto Sans JP';
              font-weight: 900;
              font-size: 30px;
              color: #7dccf4;
              line-height: 0.8; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title .class_type_logo small {
                font-size: 13px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_pink {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            margin: 50px auto 0;
            border-radius: 50%;
            border: solid 1px #f19ec2; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_pink .class_type_logo {
              text-align: center;
              font-family: 'Noto Sans JP';
              font-weight: 900;
              font-size: 30px;
              color: #f19ec2;
              line-height: 0.8; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_pink .class_type_logo small {
                font-size: 13px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_green {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 110px;
            height: 110px;
            margin: 0 auto;
            border-radius: 50%;
            border: solid 1px #7fbe27; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_green .class_type_logo {
              text-align: center;
              font-family: 'Noto Sans JP';
              font-weight: 900;
              font-size: 30px;
              color: #7fbe27;
              line-height: 0.8; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_green .class_type_logo small {
                font-size: 13px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_catch_text {
            text-align: center;
            width: 500px;
            background-color: #7dccf4;
            color: #fef23e;
            border-radius: 50px;
            margin: 30px auto 0;
            padding: 10px 0;
            font-size: 25px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_catch_text_pink {
            text-align: center;
            width: 500px;
            background-color: #f19ec2;
            color: #fef23e;
            border-radius: 50px;
            margin: 30px auto 0;
            padding: 10px 0;
            font-size: 25px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_catch_text_green {
            text-align: center;
            width: 500px;
            background-color: #7fbe27;
            color: #fef23e;
            border-radius: 50px;
            margin: 30px auto 0;
            padding: 10px 0;
            font-size: 25px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box {
            display: flex;
            justify-content: center;
            margin: 30px auto 0; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list {
              text-align: center;
              width: 30%; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list dt {
                display: inline-block;
                border-radius: 30px;
                border: solid 1px #7dccf4;
                color: #7dccf4;
                font-size: 24px;
                margin: 0 0 20px;
                padding: 10px 20px; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list dd {
                text-align: center;
                font-size: 18px; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list .class_dt_pink {
                border: solid 1px #f19ec2;
                color: #f19ec2; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list_green {
              text-align: center;
              width: 100%; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list_green dt {
                display: inline-block;
                border-radius: 20px;
                border: solid 1px #7dccf4;
                color: #7dccf4;
                font-size: 24px;
                margin: 0 0 20px;
                padding: 10px 20px; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list_green dd {
                text-align: center;
                font-size: 18px; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list_green .class_dt_pink {
                border: solid 1px #f19ec2;
                color: #f19ec2; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box_green {
            margin: 0 auto 0; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box_green .class_type_list_green {
              text-align: center;
              width: 100%; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box_green .class_type_list_green dd {
                font-size: 18px; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box_green .class_type_list_green .class_dt_green {
                display: inline-block;
                border-radius: 30px;
                border: solid 1px #7fbe27;
                color: #7fbe27;
                font-size: 24px;
                margin: 40px 0 20px;
                padding: 10px 20px; }
        body .culeaf_wrapper .class_area .class_schedule {
          margin: 80px 0 0; }
          body .culeaf_wrapper .class_area .class_schedule table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            font-size: 18px; }
          body .culeaf_wrapper .class_area .class_schedule table th {
            background-color: #7fbe27;
            color: #ffffff;
            font-size: 20px; }
          body .culeaf_wrapper .class_area .class_schedule table tr {
            border-bottom: solid 1px black; }
          body .culeaf_wrapper .class_area .class_schedule table tr:hover {
            background-color: rgba(255, 250, 202, 0.2); }
          body .culeaf_wrapper .class_area .class_schedule table th, body .culeaf_wrapper .class_area .class_schedule table td {
            text-align: center;
            width: 25%;
            padding: 15px 0; }
          body .culeaf_wrapper .class_area .class_schedule table td.icon {
            background-size: 35px;
            background-position: left 30px center;
            background-repeat: no-repeat;
            padding-left: 10px; }
          body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_box {
            display: flex;
            flex-flow: wrap;
            justify-content: space-around;
            margin-top: 50px; }
            body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_box .class_schedule_list dt {
              margin-bottom: 10px;
              font-size: 25px; }
            body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_box .class_schedule_list dd {
              font-size: 18px; }
          body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_sub {
            color: #6e6e6e; }
            body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_sub dt {
              margin-bottom: 5px;
              font-size: 15px; }
            body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_sub dd {
              font-size: 15px; }
      body .culeaf_wrapper .access_area {
        width: 835px;
        margin: 0 auto 100px; }
        body .culeaf_wrapper .access_area h2 {
          font-family: Oswald;
          font-size: 48px;
          text-align: center;
          font-weight: 700;
          margin: 60px 0; }
        body .culeaf_wrapper .access_area dl {
          margin-bottom: 30px; }
          body .culeaf_wrapper .access_area dl dt {
            text-align: center; }
          body .culeaf_wrapper .access_area dl dd {
            text-align: center; }
            body .culeaf_wrapper .access_area dl dd a {
              text-align: 1.5em;
              text-decoration: underline; }
      body .culeaf_wrapper .voice_area {
        text-align: center;
        margin-top: 100px; }
        body .culeaf_wrapper .voice_area .voice_item {
          margin: 50px 0 0; }
          body .culeaf_wrapper .voice_area .voice_item .voice_title {
            text-align: center;
            font-size: 24px; }
          body .culeaf_wrapper .voice_area .voice_item .voice_text {
            width: 700px;
            max-width: 98%;
            margin: 30px auto 0;
            line-height: 1.7;
            font-size: 20px; }
      body .culeaf_wrapper .event_area {
        text-align: center;
        margin-top: 100px; }
        body .culeaf_wrapper .event_area .event_item {
          margin-top: 60px; }
          body .culeaf_wrapper .event_area .event_item dt {
            text-align: center;
            font-family: 'Noto Sans JP';
            font-weight: 900;
            font-size: 30px;
            color: #7fbe27; }
          body .culeaf_wrapper .event_area .event_item dd {
            text-align: center;
            margin-top: 30px; }
            body .culeaf_wrapper .event_area .event_item dd img {
              width: 835px; }
      body .culeaf_wrapper .culeaf_thanks {
        text-align: center;
        font-family: Oswald;
        font-size: 40px;
        font-weight: 700;
        margin: 50px 0 100px; }
    body .culeaf_group_link {
      text-align: center;
      text-decoration: underline;
      margin-bottom: 100px; }
      body .culeaf_group_link a {
        color: #444; }
    body footer {
      font-family: Oswald;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      line-height: 1.5; } }

@media all and (max-width: 480px) {
  /* ボタンを右下に表示する */
  #pagetop {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    text-align: center;
    background-color: rgba(110, 110, 110, 0.5);
    border-radius: 50%;
    cursor: pointer; }
    #pagetop i {
      margin-top: 2px;
      color: white;
      font-size: 1em; }
  body {
    background: white; }
    body nav .nav_list li {
      margin-top: 30px;
      text-align: center; }
      body nav .nav_list li img {
        width: 25%; }
    body iframe {
      width: 100%;
      height: 230px; }
    body .culeaf_wrapper {
      color: #444;
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      height: auto;
      line-height: 1.4;
      margin: 0px 0px 50px 0px;
      text-align: center;
      width: auto;
      max-width: 100%;
      justify-content: center; }
      body .culeaf_wrapper .contact_tell {
        text-align: center;
        margin-top: 20px; }
        body .culeaf_wrapper .contact_tell a {
          font-size: 40px;
          font-family: Oswald;
          font-weight: 800; }
          body .culeaf_wrapper .contact_tell a small {
            vertical-align: middle;
            font-size: 30px;
            margin-right: 5px; }
      body .culeaf_wrapper .culeaf_entry_btn {
        text-align: center;
        margin: 30px auto 10px; }
        body .culeaf_wrapper .culeaf_entry_btn a {
          display: inline-block;
          color: #fef23e;
          background-color: #7fbe27;
          border-radius: 10px;
          font-size: 22px;
          vertical-align: middle;
          padding: 10px 30px; }
      body .culeaf_wrapper .high_culeaf_entry_btn {
        text-align: center; }
        body .culeaf_wrapper .high_culeaf_entry_btn a {
          display: inline-block;
          color: #fef23e;
          background-color: #000000;
          border-radius: 20px;
          font-size: 18px;
          vertical-align: middle;
          padding: 10px 20px;
          margin-top: 30px; }
      body .culeaf_wrapper .voice_entry_btn {
        text-align: center;
        margin: 30px auto 10px; }
        body .culeaf_wrapper .voice_entry_btn a {
          display: inline-block;
          color: #fef23e;
          background-color: #7fbe27;
          border-radius: 20px;
          font-size: 20px;
          vertical-align: middle;
          padding: 10px 30px; }
      body .culeaf_wrapper .sub_title {
        font-family: Oswald;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        margin: 20px 0 50px; }
      body .culeaf_wrapper .culeaf_instagram {
        text-align: center;
        margin: 10px 0 0;
        font-size: 13px; }
      body .culeaf_wrapper .culeaf_fa_icon {
        color: #444;
        font-size: 48px; }
      body .culeaf_wrapper .culeaf_stripe {
        min-width: 100%;
        height: 3px;
        margin: 50px 0;
        background: linear-gradient(-8deg, #7fbe27 50%, #fef23e 50%, #fef23e 100%, #7fbe27 100%, #7fbe27); }
      body .culeaf_wrapper .top_box .top_text {
        text-align: center;
        width: 100%;
        background-color: #7fbe27;
        color: #fef23e;
        font-family: 'Noto Sans JP';
        font-size: 25px;
        font-weight: 700;
        margin: 0 auto;
        padding: 20px 0;
        line-height: 1; }
        body .culeaf_wrapper .top_box .top_text i {
          font-size: 20px;
          align-items: center; }
        body .culeaf_wrapper .top_box .top_text small {
          color: #fff;
          font-size: 20px; }
      body .culeaf_wrapper .top_img_box {
        text-align: center; }
        body .culeaf_wrapper .top_img_box .main_text {
          width: 80%; }
        body .culeaf_wrapper .top_img_box .main_img {
          width: 100%; }
      body .culeaf_wrapper .info_area {
        margin: 20px 0; }
        body .culeaf_wrapper .info_area .info_text {
          text-align: center;
          font-size: 13px;
          margin-bottom: 30px;
          padding: 0 10px; }
        body .culeaf_wrapper .info_area .info_view_box {
          width: 100%;
          margin: 50px auto 0;
          padding: 30px 0;
          background-color: #fef23e; }
          body .culeaf_wrapper .info_area .info_view_box .info_view_text_img {
            text-align: center; }
            body .culeaf_wrapper .info_area .info_view_box .info_view_text_img .info_view_img {
              width: 90%; }
          body .culeaf_wrapper .info_area .info_view_box .info_view_text {
            text-align: center;
            margin-top: 10px;
            padding: 10px;
            line-height: 1.7;
            font-size: 13px; }
      body .culeaf_wrapper .pv_area {
        margin-top: 50px;
        text-align: center; }
        body .culeaf_wrapper .pv_area .pv_text {
          text-align: center;
          margin-top: 20px; }
      body .culeaf_wrapper .news_area {
        text-align: center;
        margin-top: 50px; }
        body .culeaf_wrapper .news_area p {
          margin-top: 20px; }
        body .culeaf_wrapper .news_area article {
          margin: 30px 0; }
          body .culeaf_wrapper .news_area article .post-content ul li {
            padding: 0 10px 20px; }
            body .culeaf_wrapper .news_area article .post-content ul li .info {
              margin-top: 20px; }
          body .culeaf_wrapper .news_area article .post-content .image {
            display: block;
            text-align: center; }
          body .culeaf_wrapper .news_area article .post-content .pagerbox .tablenav {
            text-align: center; }
            body .culeaf_wrapper .news_area article .post-content .pagerbox .tablenav .page-numbers {
              margin-right: 15px; }
      body .culeaf_wrapper .class_area {
        text-align: center;
        margin: 50px auto 0; }
        body .culeaf_wrapper .class_area .class_type_img {
          width: 100%;
          margin-top: 20px; }
        body .culeaf_wrapper .class_area .class_item {
          margin: 40px 0 0; }
          body .culeaf_wrapper .class_area .class_item h3 {
            text-align: center; }
            body .culeaf_wrapper .class_area .class_item h3 .class_title_img {
              width: 50%; }
          body .culeaf_wrapper .class_area .class_item .class_price {
            text-align: center;
            margin: 10px 0 0;
            padding: 10px;
            font-size: 13px;
            line-height: 1.8; }
          body .culeaf_wrapper .class_area .class_item .class_high_list {
            padding: 10px;
            font-size: 16px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;
            margin: 20px auto 15px;
            border-radius: 50%;
            border: solid 1px #7dccf4; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title .class_type_logo {
              text-align: center;
              font-family: 'Noto Sans JP';
              font-weight: 900;
              font-size: 25px;
              color: #7dccf4;
              line-height: 0.8; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title .class_type_logo small {
                font-size: 13px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_pink {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;
            margin: 30px auto 0;
            border-radius: 50%;
            border: solid 1px #f19ec2; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_pink .class_type_logo {
              text-align: center;
              font-family: 'Noto Sans JP';
              font-weight: 900;
              font-size: 25px;
              color: #f19ec2;
              line-height: 0.8; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_pink .class_type_logo small {
                font-size: 13px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_green {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;
            margin: 0 auto;
            border-radius: 50%;
            border: solid 1px #7fbe27; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_green .class_type_logo {
              text-align: center;
              font-family: 'Noto Sans JP';
              font-weight: 900;
              font-size: 23px;
              color: #7fbe27;
              line-height: 0.8; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_box_title_green .class_type_logo small {
                font-size: 13px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_catch_text {
            text-align: center;
            width: 300px;
            background-color: #7dccf4;
            color: #fef23e;
            border-radius: 22px;
            margin: 15px auto 0;
            padding: 10px 0;
            font-size: 18px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_catch_text_pink {
            text-align: center;
            width: 300px;
            background-color: #f19ec2;
            color: #fef23e;
            border-radius: 22px;
            margin: 15px auto 0;
            padding: 10px 0;
            font-size: 18px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_catch_text_green {
            text-align: center;
            width: 300px;
            background-color: #7fbe27;
            color: #fef23e;
            border-radius: 22px;
            margin: 15px auto 0;
            padding: 10px 0;
            font-size: 18px; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box {
            display: flex;
            justify-content: center;
            margin: 30px auto 0; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list {
              text-align: center;
              width: 40%; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list dt {
                text-align: center;
                color: #7dccf4;
                font-size: 16px;
                margin: 0 0 10px; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list dd {
                text-align: center;
                font-size: 13px; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list .class_dt_pink {
                color: #f19ec2; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box .class_type_list_small {
              text-align: center;
              width: 20%; }
          body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box_green {
            margin: 30px auto 0; }
            body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box_green .class_type_list_green {
              text-align: center;
              width: 100%; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box_green .class_type_list_green dd {
                font-size: 13px;
                padding: 10px; }
              body .culeaf_wrapper .class_area .class_item .class_type_box .class_type_list_box_green .class_type_list_green .class_dt_green {
                text-align: center;
                color: #7fbe27;
                font-size: 18px;
                margin: 20px 0 0; }
        body .culeaf_wrapper .class_area .class_schedule {
          margin: 50px 0 0; }
          body .culeaf_wrapper .class_area .class_schedule table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            font-size: 15px; }
          body .culeaf_wrapper .class_area .class_schedule table th {
            background-color: #7fbe27;
            color: #ffffff;
            font-size: 16px; }
          body .culeaf_wrapper .class_area .class_schedule table tr {
            border-bottom: solid 1px black; }
          body .culeaf_wrapper .class_area .class_schedule table tr:hover {
            background-color: rgba(255, 250, 202, 0.2); }
          body .culeaf_wrapper .class_area .class_schedule table th, body .culeaf_wrapper .class_area .class_schedule table td {
            text-align: center;
            width: 25%;
            padding: 15px 5px 15px 0; }
          body .culeaf_wrapper .class_area .class_schedule table td.icon {
            background-size: 35px;
            background-position: left 30px center;
            background-repeat: no-repeat;
            padding-left: 10px; }
          body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_box {
            display: flex;
            flex-flow: wrap;
            justify-content: space-around;
            margin-top: 20px; }
            body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_box .class_schedule_list dt {
              margin-bottom: 10px;
              font-size: 15px; }
            body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_box .class_schedule_list dd {
              font-size: 13px; }
            body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_box .class_schedule_list .class_title_sub {
              font-size: 13px;
              color: #868686; }
            body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_box .class_schedule_list_sub {
              color: #6e6e6e; }
              body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_box .class_schedule_list_sub dt {
                margin-bottom: 5px;
                font-size: 13px; }
              body .culeaf_wrapper .class_area .class_schedule .class_schedule_list_box .class_schedule_list_sub dd {
                font-size: 13px; }
      body .culeaf_wrapper .access_area {
        width: 100%; }
        body .culeaf_wrapper .access_area dl {
          margin-bottom: 30px; }
          body .culeaf_wrapper .access_area dl dt {
            text-align: center;
            margin-bottom: 10px; }
          body .culeaf_wrapper .access_area dl dd {
            text-align: center; }
            body .culeaf_wrapper .access_area dl dd a {
              text-align: 1.5em;
              text-decoration: underline; }
      body .culeaf_wrapper .voice_area {
        text-align: center;
        margin-top: 50px; }
        body .culeaf_wrapper .voice_area .voice_item {
          margin: 50px 0 0; }
          body .culeaf_wrapper .voice_area .voice_item .voice_title {
            text-align: center;
            font-size: 18px; }
          body .culeaf_wrapper .voice_area .voice_item .voice_text {
            width: 700px;
            max-width: 90%;
            margin: 10px auto 0;
            line-height: 1.7;
            font-size: 13px; }
      body .culeaf_wrapper .event_area {
        text-align: center;
        margin-top: 50px; }
        body .culeaf_wrapper .event_area .event_item {
          margin-top: 30px; }
          body .culeaf_wrapper .event_area .event_item dt {
            text-align: center;
            font-family: 'Noto Sans JP';
            font-weight: 900;
            font-size: 20px;
            color: #7fbe27; }
          body .culeaf_wrapper .event_area .event_item dd {
            text-align: center;
            margin-top: 10px;
            font-size: 13px; }
            body .culeaf_wrapper .event_area .event_item dd img {
              width: 100%; }
      body .culeaf_wrapper .culeaf_thanks {
        text-align: center;
        font-family: Oswald;
        font-size: 40px;
        font-weight: 700;
        margin: 50px 0 100px; }
    body .culeaf_group_link {
      text-align: center;
      text-decoration: underline;
      font-size: 13px;
      margin-bottom: 50px; }
      body .culeaf_group_link a {
        color: #444; }
    body footer {
      font-family: Oswald;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      line-height: 1.5; } }
